import request from '../axios'

/**
 * 基地禁用或启用
 */
export function UpdateStatusForBase(data) {
  return request({
    url: 'rest/BasicData/UpdateStatusForBase',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    }
  })
}

/**
 * 基地列表（分页）
 */
export function GetBaseList(data) {
  return request({
    url: 'rest/BasicData/GetBaseList',
    method: 'get',
    params: data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    }
  })
}

/**
 * 删除基地
 */
export function DeleteBase(data) {
  return request({
    url: 'rest/BasicData/DeleteBase',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    }
  })
}

/**
 * 保存基地
 */
export function SaveBase(data) {
  return request({
    url: 'rest/BasicData/SaveBase',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    }
  })
}

/**
 * 删除类别
 */
export function DeleteCategory(data) {
  return request({
    url: 'rest/BasicData/DeleteCategory',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    }
  })
}

/**
 * 类别禁用或启用
 */
export function UpdateStatusForCategory(data) {
  return request({
    url: 'rest/BasicData/UpdateStatusForCategory',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    }
  })
}

/**
 * 保存类别
 */
export function SaveCategory(data) {
  return request({
    url: 'rest/BasicData/SaveCategory',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    }
  })
}
