<template>
  <div class="box col-wrapper">
    <el-form inline>
      <el-form-item label="地区">
        <el-cascader
            v-model="form.area"
            :options="options"
            :props="{expandTrigger: 'hover',checkStrictly:true}"
            clearable>
          <template #default="{node,data}">
            <div @click="selectArea(node)">
              <span>{{ data.label }}</span>
            </div>
          </template>
        </el-cascader>
      </el-form-item>
      <el-form-item label="名称">
        <el-input v-model="form.name" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <Button type="primary" @click="GetBaseList">查询</Button>
      </el-form-item>
      <el-form-item>
        <Button @click="resetSelect">重置</Button>
      </el-form-item>
      <el-form-item>
        <Button type="success" @click="addBase">新增</Button>
      </el-form-item>
    </el-form>
    <el-table :data="tableData" max-height="70vh">
      <el-table-column align="center" label="地区">
        <template #default="scope">
          {{ isNull(scope.row.province) + " " + isNull(scope.row.city) + " " + isNull(scope.row.district) }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="名称" prop="name"></el-table-column>
      <el-table-column align="center" label="编辑人" prop="userName"></el-table-column>
      <el-table-column align="center" label="编辑时间" prop="last_update_time"></el-table-column>
      <el-table-column align="center" label="状态">
        <template #default="scope">
          <el-tag v-if="scope.row.status==='01'" type="success">启用中</el-tag>
          <el-tag v-else type="danger">已禁用</el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" min-width="180">
        <template #default="scope">
          <Button type="primary" @click="editBase(scope.row)">修改</Button>
          <!--        todo 删除时询问是否删除该条  -->

          <el-popconfirm
              :title="'确认删除 '+scope.row.name+' 吗？'"
              cancel-button-text="取消"
              confirm-button-text="确认"
              @confirm="DeleteBase(scope.row.id)">
            <template #reference>
              <Button type="danger">删除</Button>
            </template>
          </el-popconfirm>
          <Button
              v-if="scope.row.status==='02'"
              :type="'success'"
              @click="UpdateStatusForBase(scope.row.id,'01')">启用
          </Button>
          <Button
              v-else
              type="danger"
              @click="UpdateStatusForBase(scope.row.id,'02')">禁用
          </Button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        :page-sizes="[10, 20, 30, 40]"
        :total="page.total"
        layout="total, sizes, prev, pager, next, jumper"
        style="margin-top: 5px"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"/>
    <Dialog v-model="editVisiable" title="修改" @close="editVisiable=false">
      <el-form>
        <el-form-item label="地区">
          <el-cascader
              ref="edit"
              v-model="addForm.area"
              :options="options"
              :props="{expandTrigger: 'hover',checkStrictly: true}"
              clearable>
            <template #default="{node,data}">
              <div @click="selectAreaForAdd(node)">
                <span>{{ data.label }}</span>
              </div>
            </template>
          </el-cascader>
        </el-form-item>
        <el-form-item label="名称">
          <el-input v-model="addForm.name"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <Button type="primary" @click="SaveBase">确定</Button>
        <Button @click="editVisiable=false">取消</Button>
      </template>
    </Dialog>
    <Dialog v-model="addVisiable" title="新增" @close="addVisiable=false">
      <el-form>
        <el-form-item label="地区">
          <el-cascader
              ref="add"
              v-model="addForm.area"
              :options="options"
              :props="{expandTrigger: 'hover',checkStrictly: true}"
              clearable>
            <template #default="{node,data}">
              <span @click="selectAreaForAdd(node)">{{ data.label }}</span>
            </template>
          </el-cascader>
        </el-form-item>
        <el-form-item label="名称">
          <el-input v-model="addForm.name"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <Button type="primary" @click="SaveBase">确定</Button>
        <Button @click="addVisiable=false">取消</Button>
      </template>
    </Dialog>
  </div>
</template>

<script>

import * as BasicDataApi from "../../api/api/BasicData"

export default {
  name: "Category",
  data() {
    return {
      // --- 弹窗 ---
      editVisiable: false,
      addVisiable: false,
      addForm: {
        id: 0,
        name: "",
        area: ["", "", ""],
        areaNames: ["", "", ""],
      },
      // --- 表格数据 ---
      tableData: [],
      page: {
        page: 1,
        limit: 10,
        total: 100
      },
      // --- 其他 ---
      form: {
        area: [],
        base: "1",
        category: "1",
        name: "",
        text: ""
      },
      areaData: require("../../assets/json/ssq.json"),
    }
  },
  computed: {
    options() {
      return this.areaData.province
    }
  },
  activated() {
    this.GetBaseList()
  },
  methods: {
    // --- 事件 ---
    /**
     * 新增基地
     */
    addBase() {
      this.addForm = {
        id: 0,
        name: "",
        area: ["", "", ""],
        areaNames: ["", "", ""],
      }
      this.addVisiable = true
    },
    /**
     * 修改基地
     * @param row
     */
    editBase(row) {
      this.addForm.id = row.id
      let area = []
      if (row.province_code !== null) {
        area.push(row.province_code)
      }
      if (row.city_code !== null) {
        area.push(row.city_code)
      }
      if (row.district_code !== null) {
        area.push(row.district_code)
      }
      this.addForm.area = area
      this.addForm.areaNames = [row.province, row.city, row.district]
      this.addForm.name = row.name
      this.editVisiable = true
    },
    /**
     * 表格页大小
     * @param val
     */
    handleSizeChange(val) {
      this.page.limit = val
      this.page.page = 1
      this.GetBaseList()
    },
    /**
     * 翻页
     * @param val
     */
    handleCurrentChange(val) {
      this.page.page = val
      this.GetBaseList()
    },
    /**
     * 选择区域
     * @param val
     */
    selectArea(val) {
      this.form.area = val.pathValues
      this.form.areaNames = val.pathLabels
    },
    /**
     * 选择区域 新增用
     * @param val
     */
    selectAreaForAdd(val) {
      this.addForm.area = val.pathValues
      this.addForm.areaNames = val.pathLabels
    },
    /**
     * 重置查询条件
     */
    resetSelect() {
      this.form = {
        area: [],
        base: "1",
        category: "1",
        title: "",
        text: ""
      }
    },
    //   --- axios ---
    /**
     * 获取基地列表
     * @constructor
     */
    GetBaseList() {
      if (this.form.area === null) {
        this.form.area = []
      }
      const reginCode = this.form.area.length === 0 ? "" : this.form.area[this.form.area.length - 1]
      const pd = {
        regionCode: reginCode,
        regionType: this.form.area.length - 1 === -1 ? 0 : this.form.area.length - 1,
        name: this.form.name === undefined ? "" : this.form.name,
        currentPage: this.page.page,
        pagesize: this.page.limit
      }
      BasicDataApi.GetBaseList(pd)
          .then(res => {
            if (res.data.code === 200) {
              this.tableData = res.data.data.datalist
              this.page.total = res.data.data.totalSize
            } else {
              this.$message.error(res.data.message)
            }
          })
          .catch(err => {
            console.error(err)
            this.$message.error("获取基地列表失败")
          })
    },
    /**
     * 新增基地
     * @constructor
     */
    SaveBase() {
      const pd = {
        id: this.addForm.id,
        name: this.addForm.name,
        operation: "新增",
        province_code: this.addForm.area[0],
        province: this.addForm.areaNames[0],
        city_code: this.addForm.area[1],
        city: this.addForm.areaNames[1],
        district_code: this.addForm.area[2],
        district: this.addForm.areaNames[2],
      }
      BasicDataApi.SaveBase(this.$qs.stringify(pd))
          .then(res => {
            if (res.data.code === 200) {
              this.$message.success(res.data.message)
              this.GetBaseList()
              this.addVisiable = false
              this.editVisiable = false
            } else {
              this.$message.error(res.data.message)
            }
          })
          .catch(err => {
            console.error(err)
            this.$message.error("新增基地失败")
          })
    },
    /**
     * 删除基地
     * @param id 基地id
     * @constructor
     */
    DeleteBase(id) {
      const pd = {id}
      BasicDataApi.DeleteBase(this.$qs.stringify(pd))
          .then(res => {
            if (res.data.code === 200) {
              this.$message.success(res.data.message)
              this.GetBaseList()
            } else {
              this.$message.error(res.data.message)
            }
          })
          .catch(err => {
            console.error(err)
            this.$message.error("删除基地失败")
          })
    },
    /**
     * 启用/禁用基地
     * @param id 基地id
     * @param status 01 - 启用 02 - 禁用
     * @constructor
     */
    UpdateStatusForBase(id, status) {
      const pd = {id, status}
      BasicDataApi.UpdateStatusForBase(this.$qs.stringify(pd))
          .then(res => {
            if (res.data.code === 200) {
              this.$message.success(res.data.message)
              this.GetBaseList()
            } else {
              this.$message.error(res.data.message)
            }
          })
          .catch(err => {
            console.error(err)
            this.$message.error("删除基地失败")
          })
    },
    //   --- 数据处理 ---
    /**
     * 字符串中的Null转换为空字符串
     * @param val
     * @return {string|*}
     */
    isNull(val) {
      return val === null ? "" : val
    }
  }
}
</script>
<style>
.el-cascader-node > .el-radio {
  display: none;
}
</style>
<style lang="less" scoped>
.box {
  ::v-deep(.el-cascader ) {
    width: 100%;
  }
}
</style>
